.loader {
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 5rem;
  background: linear-gradient(110deg, rgba(rgb(196, 4, 4), 0.7) 45%, rgba(rgb(240, 5, 240), 0.5));

  .balls {
    .ball {
      width: 10px;
      height: 10px;
      margin: 10px auto;
      border-radius: 50px;
      background-color: #ffffff;

      &:nth-child(1) {
        -webkit-animation: right 1s infinite ease-in-out;
        -moz-animation: right 1s infinite ease-in-out;
        animation: right 1s infinite ease-in-out;
      }

      &:nth-child(2) {
        -webkit-animation: left 1.1s infinite ease-in-out;
        -moz-animation: left 1.1s infinite ease-in-out;
        animation: left 1.1s infinite ease-in-out;
      }

      &:nth-child(3) {
        -webkit-animation: right 1.05s infinite ease-in-out;
        -moz-animation: right 1.05s infinite ease-in-out;
        animation: right 1.05s infinite ease-in-out;
      }

      &:nth-child(4) {
        -webkit-animation: left 1.15s infinite ease-in-out;
        -moz-animation: left 1.15s infinite ease-in-out;
        animation: left 1.15s infinite ease-in-out;
      }

      &:nth-child(5) {
        -webkit-animation: right 1.1s infinite ease-in-out;
        -moz-animation: right 1.1s infinite ease-in-out;
        animation: right 1.1s infinite ease-in-out;
      }

      &:nth-child(6) {
        -webkit-animation: left 1.05s infinite ease-in-out;
        -moz-animation: left 1.05s infinite ease-in-out;
        animation: left 1.05s infinite ease-in-out;
      }

      &:nth-child(7) {
        -webkit-animation: right 1s infinite ease-in-out;
        -moz-animation: right 1s infinite ease-in-out;
        animation: right 1s infinite ease-in-out;
      }
    }
  }
}

@-webkit-keyframes right {
  0% {
    -webkit-transform: translate(-15px)
  }

  50% {
    -webkit-transform: translate(15px)
  }

  100% {
    -webkit-transform: translate(-15px)
  }
}

@-webkit-keyframes left {
  0% {
    -webkit-transform: translate(15px)
  }

  50% {
    -webkit-transform: translate(-15px)
  }

  100% {
    -webkit-transform: translate(15px)
  }
}

@-moz-keyframes right {
  0% {
    -moz-transform: translate(-15px)
  }

  50% {
    -moz-transform: translate(15px)
  }

  100% {
    -moz-transform: translate(-15px)
  }
}

@-moz-keyframes left {
  0% {
    -moz-transform: translate(15px)
  }

  50% {
    -moz-transform: translate(-15px)
  }

  100% {
    -moz-transform: translate(15px)
  }
}

@keyframes right {
  0% {
    transform: translate(-15px)
  }

  50% {
    transform: translate(15px)
  }

  100% {
    transform: translate(-15px)
  }
}

@keyframes left {
  0% {
    transform: translate(15px)
  }

  50% {
    transform: translate(-15px)
  }

  100% {
    transform: translate(15px)
  }
}