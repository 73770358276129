@import './variables';

body,
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.3s ease;
}

body {
  font-size: 20px;
  overflow: hidden;
  background-color: $global_primary;
  color: $global_text_color;
  font-family: brandon, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

a,
a:visited {
  text-decoration: none;
  color: #0000ee;

  &:hover {
    text-decoration: underline;
  }

  &.button {
    cursor: pointer;
    text-decoration: none;
    color: white;
  }
}

.button {
  cursor: pointer;
  color: white;
  background-color: #106dff;
}

#root>:nth-child(3) {
  padding: 7rem 1.5rem 0;
}

@media screen and (min-width: 768px) {
  #root>:nth-child(3) {
    padding: 9rem 2rem 0;
  }
}

@media screen and (min-width: 1200px) {
  #root>:nth-child(3) {
    padding: 9rem 5rem 0;
  }
}

*::before,
*::after {
  content: '';
  bottom: 0;
  width: 0px;
  position: absolute;
  border-radius: 999px;
  height: 3px;
  background-color: #00b2e1;

  &::after {
    right: 50%;
  }

  &::before {
    left: 50%;
  }
}



.disabled {

  a,
  * {
    color: gray;
    cursor: not-allowed;
  }
}