// Brandon Grotesque :

@font-face {
  font-family: brandon;
  src: url(../app/static/font/brandon/Brandon_reg.otf);
}
@font-face {
  font-family: brandon-italics;
  src: url(../app/static/font/brandon/Brandon_reg_it.otf);
}
@font-face {
  font-family: brandon-medium;
  src: url(../app/static/font/brandon/Brandon_med.otf);
}
@font-face {
  font-family: brandon-medium-italics;
  src: url(../app/static/font/brandon/Brandon_med_it.otf);
}
@font-face {
  font-family: brandon-bold;
  src: url(../app/static/font/brandon/Brandon_bld.otf);
}
@font-face {
  font-family: brandon-bold-italics;
  src: url(../app/static/font/brandon/Brandon_bld_it.otf);
}
@font-face {
  font-family: brandon-thin;
  src: url(../app/static/font/brandon/Brandon_thin.otf);
}
@font-face {
  font-family: brandon-thin-italics;
  src: url(../app/static/font/brandon/Brandon_thin_it.otf);
}
